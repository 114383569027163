/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
const sassVars = require('!!sass-variables-loader!../styles/variables.scss'); // import the variables from scss

export const Constants = {
  MQ_XSMALL: parseInt(sassVars.MQ_XSMALL, 10),
  MQ_SMALL: parseInt(sassVars.MQ_SMALL, 10),
  MQ_MEDIUM: parseInt(sassVars.MQ_MEDIUM, 10),
  MQ_LARGE: parseInt(sassVars.MQ_LARGE, 10),
  MQ_XLARGE: parseInt(sassVars.MQ_XLARGE, 10),
  MQ_XXLARGE: parseInt(sassVars.MQ_XXLARGE, 10),

  MOBILE: 'mobile',
  DESKTOP: 'desktop',

  BRANDS: 'brands',
  CREATORS: 'creators',

  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',

  simplePageWrapperClasses: 'max-w-4xl mx-auto pt-48 pb-48 px-[30px] lg:px-0',
  simplePageParagraphClasses: 'pb-5 lg:pb-10',

  square: 'after:block after:w-full after:pt-[100%]',
  absoluteCenter: 'block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2',
  lgAbsoluteCenter: 'lg:block lg:absolute lg:left-1/2 lg:top-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2',

  navLink: 'text-charcoal pt-5 pb-6 pl-2 border-b md:border-0 border-charcoal block lowercase',
  stickyScrollButton: 'absolute right-1/2 transform translate-x-1/2 lg:translate-x-0 md:right-8 lg:right-16 h-screen sm:responsive-h-screen tp:responsive-h-screen tl:responsive-h-screen top-0 z-20 pb-6 md:pb-16 flex flex-col justify-end z-40',
  stickyScrollButtonMobile: 'absolute right-1/2 transform translate-x-1/2 bottom-8',

  contentMaxWidth: 'max-w-[100%] my-0',

  panelBase: 'relative w-screen h-screen lg:min-h-[700px]',
  fadeUp: 'transition-all duration-500 transform translate-y-2 opacity-0',
  fadeIn: 'transition-all duration-500 opacity-0',
  BASE_URL: process.env.GATSBY_SITE_URL,
};
