import { Constants } from '../../constants';

// defining the State
const initialState = {
  menuIsOpen: false,
  scrollReady: false,
  isMobile: null,
  form: { isOpen: false, type: Constants.BRANDS },
  hideHeader: false,
  headerColorOverride: null,
  isTablet: null,
  isLandscape: false,
};

// TYPES
const HANDLE_MENU_STATUS = 'HANDLE_MENU_STATUS';
const TOGGLE_FORM = 'TOGGLE_FORM';
const SCROLL_READY = 'SCROLL_READY';
const STORE_IS_MOBILE = 'STORE_IS_MOBILE';
const SET_HIDE_HEADER = 'SET_HIDE_HEADER';
const SET_HEADER_COLOR_OVERRIDE = 'SET_HEADER_COLOR_OVERRIDE';
const STORE_IS_LANDSCAPE = 'STORE_IS_LANDSCAPE';
const STORE_IS_TABLET = 'STORE_IS_TABLET';
// ACTIONS
export const toggleMenuOpen = (status) => ({
  type: HANDLE_MENU_STATUS,
  payload: status,
});
export const toggleForm = (isOpen, type) => ({
  type: TOGGLE_FORM,
  payload: { isOpen, type },
});
export const scrollReady = (isReady) => ({
  type: SCROLL_READY,
  payload: { scrollReady: isReady },
});
export const storeIsMobile = (isMobile) => ({
  type: STORE_IS_MOBILE,
  payload: { isMobile },
});
export const storeIsLandscape = (isLandscape) => ({
  type: STORE_IS_LANDSCAPE,
  payload: { isLandscape },
});
export const storeIsTablet = (isTablet) => ({
  type: STORE_IS_TABLET,
  payload: { isTablet },
});
export const setHideHeader = (hideHeader) => ({
  type: SET_HIDE_HEADER,
  payload: hideHeader,
});
export const setHeaderColorOverride = (headerColor) => ({
  type: SET_HEADER_COLOR_OVERRIDE,
  payload: headerColor,
});
// SELECTORS
export const selectMenuIsOpen = (state) => state.utils.menuIsOpen;
export const selectFormStatus = (state) => state.utils.form;
export const selectScrollReady = (state) => state.utils.scrollReady;
export const selectIsMobile = (state) => state.utils.isMobile;
export const selectIsLandscape = (state) => state.utils.isLandscape;
export const selectIsTablet = (state) => state.utils.isTablet;
export const selectHideHeader = (state) => state.utils.hideHeader;
export const selectHeaderColorOverride = (state) => state.utils.headerColorOverride;

// REDUCER
const utilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_MENU_STATUS:
      return {
        ...state,
        menuIsOpen: action.payload,
      };
    case TOGGLE_FORM:
      return {
        ...state,
        form: {
          isOpen: action.payload.isOpen,
          type: action.payload?.type,
        },
      };
    case SCROLL_READY:
      return {
        ...state,
        scrollReady: action.payload.scrollReady,
      };
    case STORE_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload.isMobile,
      };
    case STORE_IS_TABLET:
      return {
        ...state,
        isTablet: action.payload.isTablet,
      };
    case STORE_IS_LANDSCAPE:
      return {
        ...state,
        isLandscape: action.payload.isLandscape,
      };
    case SET_HIDE_HEADER:
      return {
        ...state,
        hideHeader: action.payload,
      };
    case SET_HEADER_COLOR_OVERRIDE:
      return {
        ...state,
        headerColorOverride: action.payload,
      };
    default:
      return state;
  }
};

export default utilsReducer;
