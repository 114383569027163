import './src/styles/index.scss';
// import './src/styles/Osano.scss';
import 'intersection-observer';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import detectMobile from './src/utils/detectMobile';

export { default as wrapRootElement } from './src/state/reduxWrapper';

export const onClientEntry = () => {
  detectMobile();
  if (typeof window.IntersectionObserver === 'undefined') {
    // eslint-disable-next-line global-require
    require('intersection-observer');
  }
  Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get() {
      return !!(
        this.currentTime > 0
        && !this.paused
        && !this.ended
        && this.readyState > 2
      );
    },
  });
};

export const onRouteUpdate = () => {
  clearAllBodyScrollLocks();
};
